import { Component, OnInit } from '@angular/core';
import { AppSandboxService } from '../../app-sandbox.service';
import { Tab } from 'src/modules/types/interfaces/menu';
import { ACCESS } from '../../tools/access';
import { CurrentUser } from 'src/modules/types/interfaces';
import { CognitoService } from '../../services/cognito/cognito.service';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'kyt-homepage',
  template: `
     <div class="main-container">
            <kyt-side-nav style="margin: 0;padding: 0;" [listOfTabs]="tabs"></kyt-side-nav>
            <kyt-alert-insight [alertInsight]="alertInsightObservable$ | async"></kyt-alert-insight>
      </div>

  `,
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  tabs: Tab[] = [
    {
      index: 0, icon: '', label: 'ALERT LISTES', path: null, children: [
        { index: 0.1, icon: 'credit_card', label: 'CARD ALERTS', path: '/kyt/card-alerts' },
        { index: 0.2, icon: 'attach_money', label: 'CHARGE+ ALERTS', path: '/kyt/charge-alerts' },
        { index: 0.3, icon: 'loupe', label: 'BADGE ALERTS', path: '/kyt/badge-alerts' },
        { index: 0.4, icon: 'trending_up', label: 'MACHINE LEARNING', path: '/kyt/machine-learning' }
      ]
    },

    {
      index: 1, icon: '', label: 'ALERTS FOLLOW-UP', path: null, children: [
        { index: 1.1, icon: 'credit_card', label: 'CARDS FOLLOW UP', path: '/alerts-follow-up/cards' },
        { index: 1.2, icon: 'loupe', label: 'BADGES FOLLOW UP', path: '/alerts-follow-up/badges' },
        { index: 1.3, icon: 'person_pin', label: 'CLIENTS PRECISION', path: '/alerts-follow-up/client-precision' }]
    },

    {
      index: 2, icon: '', label: 'BLACKLIST', path: null, children: [
        { index: 2.1, icon: 'euro_symbol', label: 'TRANSACTIONS ARCY', path: '/black-list/transaction-arcy' },
        { index: 2.2, icon: 'vertical_split', label: 'CUSTOMER FACTORISATIONS', path: '/black-list/customer-factorisation' },
        {
          index: 2.3, icon: 'brightness_high', label: 'PARAMETERS', path: null,
          children: [
            {
              icon: 'not_interested', label: 'Network Parameters & Blacklisted BINs',
              path: '/black-list/parameters/network-parameters-blacklisted-bins', index: 41
            },
            { icon: 'ballot', label: 'List Length', path: '/black-list/parameters/list-length', index: 42 },
            {
              icon: 'settings', label: 'Parametrage Modeles De Saturation',
              path: '/black-list/parameters/parametrage-modeles-de-saturation', index: 43
            },
            { icon: 'description', label: 'Blacklist Parameters', path: '/black-list/parameters/blacklist-parameters', index: 44 }
          ]
        },
        { index: 2.4, icon: 'help_outline', label: 'LN_FORCE', path: '/black-list/ln-force' },
        { index: 2.5, icon: 'save_alt', label: 'IMPORT DANS CARDPRO', path: '/black-list/import-dans-cardpro' },
        { index: 2.6, icon: 'network_cell', label: 'CUSTOMERS BY NETWORKS', path: '/black-list/customers-by-networks' },
        { index: 2.7, icon: 'swap_calls', label: 'SUIVI PIPELINES GENERATION BLACKLIST', path: '/black-list/blacklist-pipelines' }
      ]
    },

    {
      index: 3, icon: '', label: 'CONFIGURATION', path: null, children: [
        { index: 3.1, icon: 'storage', label: 'RULES CONFIGURATION', path: '/configuration/rules-configuration' },
        { index: 3.2, icon: 'chat_bubble_outline', label: 'COMMENTS CONFIGURATION', path: '/configuration/comments-configuration' },
        { index: 3.3, icon: 'gesture', label: 'DNA CONFIGURATION', path: '/configuration/dna-configuration' },
        { index: 3.4, icon: 'tune', label: 'MAPPING NETWORK CODES', path: '/configuration/mapping-netowork-codes' },
        { index: 3.5, icon: 'local_gas_station', label: 'MICA PRODUCTS', path: '/configuration/mica-products' },
        { index: 3.6, icon: 'settings', label: 'EXCLUDE MICA', path: '/configuration/exclude-mica' },
        { index: 3.7, icon: 'settings_applications', label: 'PROVIDERS CONFIGURATION MICA & TOP-8', path: '/configuration/providers-configuration' },
        { index: 3.8, icon: 'transform', label: 'MAPPING FAMILY OF PRODUCTS', path: null },
        { index: 3.9, icon: 'report_problem', label: 'NON-MAPPED PRODUCTS FAMILY', path: '/configuration/non-mapped-product-family' },
      ]
    },
    {
      index: 4, icon: '', label: 'ADMINISTRATION', path: null, children: [
        { index: 4.1, icon: 'supervisor_account', label: 'MANAGE USERS', path: '/administration' },
      ]
    },
    {
      index: 5, icon: '', label: 'AUDITABILITY', path: null, children: [
        { index: 5.1, icon: 'file_copy', label: 'MANAGE USERS', path: '/auditability' },
      ]
    }

  ];

  alertInsightObservable$ = this.sb.alertInsightObservable;
  user: CurrentUser = this.sb.currentUser?.getValue();
  childIndex: number;
  igg: string;
  constructor(private sb: AppSandboxService, private cognitoService: CognitoService, private configService: ConfigService) { }
  ngOnInit(): void {
    this.getIgg();
  }

  isTabAccessible(): void {
    const access = ACCESS;
    const tabsCopy = JSON.parse(JSON.stringify(this.tabs));
    tabsCopy.forEach(tab => {
      const mainRoute = tab.children[0].path.split('/')[1];
      if (access[mainRoute].main_route_access.includes(this.user?.role)) {
        const index = this.tabs.findIndex((e) => e.index === tab.index);
        this.tabs[index].children = this.isChildAccessible(tab.children, mainRoute);
      } else {
        const index = this.tabs.findIndex((e) => e.index === tab.index);
        this.tabs.splice(index, 1);
      }
    });
  }

  isChildAccessible(tab: Tab[], mainRoute: string): Tab[] {
    const access = ACCESS;
    const tabsCopy = tab;
    for (const iroute of access[mainRoute].internal_routes) {
      for (const tb of tabsCopy) {
        if (tb.path === iroute.route && !iroute.route_access.includes(this.user.role)) {
          const index = tab.findIndex((e) => e.index === tb.index);
          tab.splice(index, 1);
          break;
        }
        if (tb?.children?.length > 0 && this.childIndex !== tb.index) {
          tb.children = this.isChildAccessible(tb.children, mainRoute);
          this.childIndex = tb.index;
        }
      }
    }
    return tab;
  }

  getIgg(): void {
    this.cognitoService.getUser().then((info: { username: string }) => {
      if (info.username.indexOf('DigitalPass_') !== -1) {
        this.igg = info.username.split('DigitalPass_')[1];
      } else {
        this.igg = info.username.split(this.configService.getConfig()?.gigya + '_')[1];
      }
    }).then(() => {
      this.sb.getAlertsTreatment(this.igg);
      this.isTabAccessible();
    });
  }

}
