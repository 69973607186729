import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kyt-alert-details',
  template: `
    <button mat-dialog-close class="mat-cancel-btn" mat-icon-button>
      <mat-icon>close icon</mat-icon>
    </button>
     <span><h3>Alerts Processed</h3></span>
    <div class="container">
      <span class="wrapper">
        <span class="w-50"><h4>Product Family</h4></span>
        <span class="w-50"><h4>Alerts Count</h4></span>
      </span>
    <ng-container *ngFor="let pdtFamily of data?.productFamilyCount">
        <span class="wrapper">
          <span class="w-50"><label>{{pdtFamilyMapping[pdtFamily.code]}}</label></span>
          <span class= "w-50" ><label>{{pdtFamily.count}}</label></span>
        </span>
      </ng-container>
    </div>
  `,
  styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent {
  constructor(private matdialogRef: MatDialogRef<AlertDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  this.matdialogRef.disableClose = true;
}
  pdtFamilyMapping = { FA01: 'Carb', FA16: 'Tolls', FA26: 'WASH', FA17 : 'Parking', FA02: 'Shop', FA10: 'EV'};
}
